import React, { Component } from "react";
import * as d3 from "d3";
import { returnDecimalNum } from "../../util";

class BarChart extends Component {
    constructor(props) {
        super(props);
        const clinicId = sessionStorage.getItem('ClinicId');
        this.state = {
            data: this.props.data,
            dataNational: this.props.dataNational,
            showNational: clinicId === '9999' ? false : (this.props.showNational || false),
            chartTitle: this.props.chartTitle || 'Bar Chart'
        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showNational !== this.props.showNational) {
            const clinicId = sessionStorage.getItem('ClinicId');
            this.setState({
                showNational: clinicId === '9999' ? false : this.props.showNational
            }, () => {
                d3.select(this.ref.current).selectAll("*").remove();
                this.init();
            });
        }
    }

    init() {
        const report = this.state.data;
        const reportNational = this.state.dataNational;
        const showNational = this.state.showNational;
        const barWidth = showNational ? 30 : 70;
        const barSpacing = showNational ? 10 : 0;

        // Helper function for consistent tooltip handling
        const handleTooltip = (event, d, isNational) => {
            const currentBar = d3.select(event.currentTarget);
            const i = parseInt(currentBar.attr("data-index"));
            const barX = parseInt(currentBar.attr("x"));

            if (showNational) {
                // Get both bars for the current index
                const clinicBar = series
                    .select('.highcharts-series:first-of-type')
                    .select(`rect[data-index="${i}"]`);

                const nationalBar = series
                    .select('.highcharts-series:nth-of-type(2)')
                    .select(`rect[data-index="${i}"]`);

                // Get Y positions
                const clinicY = clinicBar.empty() ? 291 : parseInt(clinicBar.attr("y"));
                const nationalY = nationalBar.empty() ? 291 : parseInt(nationalBar.attr("y"));

                // Use the higher position (smaller Y value)
                const tooltipY = Math.min(clinicY, nationalY) - 30;

                currentBar.style("opacity", "0.8");

                currentBar.style("opacity", "0.8");
                d3.select("div.highcharts-tooltip")
                    .style("left", `${barX + (isNational ? 200 : 260)}px`)
                    .style("top", `${tooltipY + 20}px`);

            } else {
                // If no national data, just use current bar position
                const barY = parseInt(currentBar.attr("y"));

                currentBar.style("opacity", "0.8");

                d3.select("div.highcharts-tooltip")
                    .style("left", `${barX + 260}px`)
                    .style("top", `${barY - 10}px`);
            }

            // Set tooltip text maintaining existing format
            const headers = isNational ? reportNational.RowHeaders : report.RowHeaders;
            d3.select("#tooltipText").html(
                `${isNational ? "National " : ""}Age of Patient ${headers[i + 1]}: ${Number(d).toFixed(1)}`
            );
        };

        var svg = this.ref.current;

        svg = d3
            .select(svg)
            .attr("version", "1.1")
            .attr(
                "style",
                "font-family: Arial, Helvetica, sans-serif; font-size: 12px;"
            );

        svg
            .append("defs")
            .append("clipPath")
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 599)
            .attr("height", 291);

        svg
            .append("rect")
            .attr("x", 0.5)
            .attr("y", 0.5)
            .attr("width", 666)
            .attr("height", 398)
            .attr("strokeWidth", 1)
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg
            .append("rect")
            .attr("x", 59)
            .attr("y", 10)
            .attr("width", 599)
            .attr("height", 291)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg.append("desc").text("Patient Characteristics for Age of patient");

        // Grid lines
        svg
            .append("g")
            .attr("class", "highcharts-grid")
            .attr("zindex", 1)
            .selectAll("path")
            .data([358.5, 507.5, 658.5, 208.5, 58.5])
            .enter()
            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M ${d} 10 L ${d} 301`;
            })
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 3)
            .attr("zindex", 1)
            .attr("opacity", 1);

        svg
            .append("g")
            .attr("class", "highcharts-grid")
            .attr("zindex", 1)
            .selectAll("path")
            .data([9.5, 107.5, 204.5, 301.5])
            .enter()
            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M 59 ${d} L 658 ${d}`;
            })
            .attr("stroke", "#C0C0C0")
            .attr("strokeWidth", 1)
            .attr("zindex", 1)
            .attr("opacity", 1);

        // Axes
        var axisX = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisX
            .append("text")
            .attr("x", 358.5)
            .attr("y", 343)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 0)")
            .attr("class", "highcharts-xaxis-title barchartverticalsmallX")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")
            .append("tspan")
            .text('Age of patient');

        var axisY = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisY
            .append("text")
            .attr("x", 26)
            .attr("y", 155.5)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 0) rotate(270 26 155.5)")
            .attr("class", "highcharts-yaxis-title barchartverticalsmallYaxis")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")
            .append("tspan")
            .text('Number');

        var series = svg
            .append("g")
            .attr("class", "highcharts-series-group")
            .attr("zindex", 3);

        // Clinic data bars
        series
            .append("g")
            .attr("class", "highcharts-series highcharts-tracker")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr("transform", "translate(59, 10) scale(1 1)")
            .selectAll("rect")
            .data([
                report.DataRows[0][1].replace("%", "") * 1.0,
                report.DataRows[0][2].replace("%", "") * 1.0,
                report.DataRows[0][3].replace("%", "") * 1.0,
                report.DataRows[0][4].replace("%", "") * 1.0
            ])
            .enter()
            .append("rect")
            .attr("data-index", (d, i) => i)
            .attr("x", (d, index) => 39 + 150 * index)
            .attr("y", d => {
                if (d === 0) return 292;
                const minHeight = 10;
                const maxHeight = 292;
                const heightRange = maxHeight - minHeight;
                const scaledHeight = minHeight + (heightRange * (d / 3));
                return 292 - scaledHeight;
            })
            .attr("height", d => {
                if (d === 0) return 0;
                const minHeight = 10;
                const maxHeight = 292;
                const heightRange = maxHeight - minHeight;
                return minHeight + (heightRange * (d / 3));
            })
            .attr("width", barWidth)
            .attr("fill", "#005ea2")
            .attr("rx", 0)
            .attr("ry", 0)
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 2)
            .on("mouseover", (event, d) => handleTooltip(event, d, false))
            .on("mouseout", (event) => {
                d3.select(event.currentTarget)
                    .style("opacity", "1");
                d3.select("div.highcharts-tooltip")
                    .style("left", "387px")
                    .style("top", "-9999px");
            });

        // National data bars
        if (showNational) {
            series
                .append("g")
                .attr("class", "highcharts-series highcharts-tracker")
                .attr("visibility", "visible")
                .attr("zindex", 0.1)
                .attr("transform", "translate(59, 10) scale(1 1)")
                .selectAll("rect")
                .data([
                    reportNational.DataRows[0][1].replace("%", "") * 1.0,
                    reportNational.DataRows[0][2].replace("%", "") * 1.0,
                    reportNational.DataRows[0][3].replace("%", "") * 1.0,
                    reportNational.DataRows[0][4].replace("%", "") * 1.0
                ])
                .enter()
                .append("rect")
                .attr("data-index", (d, i) => i)
                .attr("x", (d, index) => 39 + barWidth + barSpacing + 150 * index)
                .attr("y", d => {
                    if (d === 0) return 292;
                    const minHeight = 10;
                    const maxHeight = 292;
                    const heightRange = maxHeight - minHeight;
                    const scaledHeight = minHeight + (heightRange * (d / 3));
                    return 292 - scaledHeight;
                })
                .attr("height", d => {
                    if (d === 0) return 0;
                    const minHeight = 10;
                    const maxHeight = 292;
                    const heightRange = maxHeight - minHeight;
                    return minHeight + (heightRange * (d / 3));
                })
                .attr("width", barWidth)
                .attr("fill", "#73b3e7")
                .attr("rx", 0)
                .attr("ry", 0)
                .attr("stroke", "#FFFFFF")
                .attr("strokeWidth", 2)
                .on("mouseover", (event, d) => handleTooltip(event, d, true))
                .on("mouseout", (event) => {
                    d3.select(event.currentTarget)
                        .style("opacity", "1");
                    d3.select("div.highcharts-tooltip")
                        .style("left", "387px")
                        .style("top", "-9999px");
                });
        }

        // Legend
        var legend = svg
            .append("g")
            .attr("class", "highcharts-legend")
            .attr("zindex", 7)
            .attr("transform", "translate(301, 346)")
            .append("g")
            .attr("zindex", 1);

        // Clinic legend item
        var clinicItem = legend
            .append("g")
            .attr("class", "highcharts-legend-item")
            .attr("zindex", 1)
            .attr("transform", showNational ? "translate(-50, 3)" : "translate(8, 3)");

        clinicItem
            .append("text")
            .attr("x", 21)
            .attr("y", 17)
            .attr(
                "style",
                "color: Black; font-size: 14px; font-weight: bold; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;"
            )
            .attr("text-anchor", "start")
            .text(sessionStorage.getItem('ClinicId') === '9999' ? "National" : "Clinic");

        clinicItem
            .append("rect")
            .attr("x", 0)
            .attr("y", 6)
            .attr("width", 16)
            .attr("height", 12)
            .attr("fill", "#005ea2");

        // National legend item
        if (showNational) {
            var nationalItem = legend
                .append("g")
                .attr("class", "highcharts-legend-item")
                .attr("zindex", 1)
                .attr("transform", "translate(50, 3)");

            nationalItem
                .append("text")
                .attr("x", 21)
                .attr("y", 17)
                .attr(
                    "style",
                    "color: Black; font-size: 14px; font-weight: bold; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;"
                )
                .attr("text-anchor", "start")
                .text("National");

            nationalItem
                .append("rect")
                .attr("x", 0)
                .attr("y", 6)
                .attr("width", 16)
                .attr("height", 12)
                .attr("fill", "#73b3e7");
        }

        // X-axis labels
        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-xaxis-labels")
            .attr("zindex", 7)
            .selectAll("text")
            .data([
                report.RowHeaders[1],
                report.RowHeaders[2],
                report.RowHeaders[3],
                report.RowHeaders[4]
            ])
            .enter()
            .append("text")
            .attr("x", (d, index) => 133.8 + index * 150)
            .attr("y", 323)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 0)")
            .attr(
                "style",
                "color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(d => d);

        // Y-axis labels
        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-yaxis-labels")
            .attr("zindex", 7)
            .selectAll("text")
            .data([0, 1, 2, 3])
            .enter()
            .append("text")
            .attr("x", 44)
            .attr("y", (d, index) => 307 - index * 97)
            .attr("text-anchor", "end")
            .attr("transform", "translate(0, 0)")
            .attr(

                "style",
                "width: 200px; color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(d => d);

        // Add tooltip container
        svg
            .append("g")
            .attr("class", "highcharts-tooltip")
            .attr("zindex", 8)
            .attr("style", "cursor: default; padding: 0; white-space: nowrap;")
            .attr("transform", "translate(96, -9999)")
            .attr("opacity", 0)
            .append("path")
            .attr("fill", "rgba(255, 255, 255, 0)")
            .attr(
                "d",
                "M 3 0 L 101 0 C 104 0 104 0 104 3 L 104 60 C 104 63 104 63 101 63 L 58 63 52 69 46 63 3 63 C 0 63 0 63 0 60 L 0 3 C 0 0 0 0 3 0"
            );
    }

    render() {
        const chartTitle = this.state.chartTitle || 'Vertical Bar Chart';
        return <svg className="barChartVerticalSmall" width="668" height="400" ref={this.ref} role="img" aria-labelledby={chartTitle}></svg>;
    }
}

export default BarChart;